/*
    Validation.js

    Validation core

    Juan Carlos Espinosa Ceniceros
    EducarUno 2018
*/

// Extend validator
$.validator.addMethod(
    'whitespaces',
    (value, element) => {
        return value.indexOf(' ') < 0 && value != '';
    },
    Lang.get('Blank spaces are not allowed')
);

$.validator.addMethod(
    'alpha_dash',
    (value, element) => {
        var regex = new RegExp('^[a-zA-Z0-9_-]+$');
        return value.match(regex);
    },
    Lang.get('Only letters, numbers, hyphen and underscore are allowed')
);

$.validator.setDefaults({
    ignore: false,
    errorElement: 'span',
    errorClass: 'is-invalid small text-danger',
    validClass: 'is-valid'
});

const cfg = {
    errorPlacement: {
        default(error, element) {
            if (
                $(element)
                    .parent()
                    .hasClass('input-group')
            ) {
                $(element)
                    .parent()
                    .parent()
                    .append(error);
            } else if (
                $(element)
                    .parent()
                    .is('label')
            ) {
                $(element)
                    .parent()
                    .parent()
                    .parent()
                    .append(error);
            } else {
                $(element)
                    .parent()
                    .append(error);
            }
        },
        twoLevel(error, element) {
            $(element)
                .parent()
                .parent()
                .parent()
                .append(error);
        },
        file(error, element) {
            if ($(element).attr('type') == 'file') {
                $(element)
                    .parent()
                    .parent()
                    .parent()
                    .parent()
                    .append(error);
            } else {
                if (
                    $(element)
                        .parent()
                        .hasClass('input-group')
                ) {
                    $(element)
                        .parent()
                        .parent()
                        .append(error);
                } else {
                    $(element)
                        .parent()
                        .append(error);
                }
            }
        }
    },
    highlight: {
        default(element, errorClass, validClass) {
            $(element)
                .removeClass(validClass)
                .addClass(errorClass);
        },
        twoLevel(element, errorClass, validClass) {
            if (
                $(element)
                    .parent()
                    .hasClass('input-group')
            ) {
                $(element)
                    .parent()
                    .children()
                    .removeClass(validClass)
                    .removeClass(errorClass);
            }
            $(element)
                .removeClass(validClass)
                .addClass(errorClass);
        },
        file(element, errorClass, validClass) {
            $(element)
                .removeClass(validClass)
                .addClass(errorClass);
        }
    },
    unhighlight: {
        default(element, errorClass, validClass) {
            $(element)
                .removeClass(errorClass)
                .addClass(validClass);
            $(element)
                .parent()
                .children('span.' + errorClass)
                .remove();
        },
        twoLevel(element, errorClass, validClass) {
            if (
                $(element)
                    .parent()
                    .hasClass('input-group')
            ) {
                $(element)
                    .parent()
                    .children()
                    .removeClass(errorClass)
                    .removeClass(validClass);
            }
            $(element)
                .removeClass(errorClass)
                .addClass(validClass);
            $(element)
                .parent()
                .parent()
                .children('span.' + errorClass)
                .remove();
        },
        file(element, errorClass, validClass) {
            $(element)
                .removeClass(errorClass)
                .addClass(validClass);
            $(element)
                .parent()
                .children('span.' + errorClass)
                .remove();
        }
    },
    invalidHandler: {
        default(e, validator) {
            toastr['error'](
                Lang.get('There are errors in the validation of the fields')
            );
        },
        collapsePanel(e, validator) {
            for (var i = 0; i < validator.errorList.length; i++) {
                $(validator.errorList[i].element)
                    .closest('.panel-collapse.collapse')
                    .collapse('show');
            }
        }
    },
    submitHandler: {
        default($form) {
            $('input[type="submit"]').attr('disabled', true);
            $form.submit();
        }
    }
};

export default {
    init(form_selector, rules, messages = {}, options = {}) {
        const $form = $(form_selector);
        const forms_count = $form.length;
        if (forms_count == 0) {
            console.warn('Form [' + form_selector + '] does not exist');
            return;
        }

        const config = {
            rules: rules,
            messages: messages,
            invalidHandler:
                cfg.invalidHandler[options['invalidHandler']] ||
                cfg.invalidHandler['default'],
            errorPlacement:
                cfg.errorPlacement[options['errorPlacement']] ||
                cfg.errorPlacement['default'],
            highlight:
                cfg.highlight[options['highlight']] || cfg.highlight['default'],
            unhighlight:
                cfg.unhighlight[options['unhighlight']] ||
                cfg.unhighlight['default'],
            submitHandler:
                options['submitHandler'] || cfg.submitHandler['default']
        };

        try {
            const results = [];
            $form.each(i => {
                let r = $($form[i]).validate(config);
                results.push(r);
            });
            return forms_count > 1 ? results : results.shift();
        } catch (e) {
            console.error(e);
            return e;
        }
    }
};
