import Validator from '../utils/Validator';

Validator.init('form#form-register', {
    first_name: {
        required: true,
        rangelength: [1, 200]
    },
    last_name: {
        rangelength: [1, 200]
    },
    username: {
        required: true,
        rangelength: [1, 50],
        whitespaces: true,
        alpha_dash: true
    },
    email: {
        required: true,
        email: true,
        rangelength: [1, 200]
    },
    password: {
        required: true,
        rangelength: [8, 100]
    },
    password_confirmation: {
        required: true,
        rangelength: [8, 100],
        equalTo: 'input[name="password"]'
    },
    conditions: {
        required: true
    },
    'g-recaptcha-response': {
        required: true
    }
});
